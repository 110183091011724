import "./TextPage.css";

const HaspaJoker = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage">
          <div className="budniMobileBreak">
            <h2>Angebote für</h2>
            <h2>HaspaJoker-Kunden</h2>
          </div>

          <h4>
            Das Girokonto der Hamburger Sparkasse gibt es seit vielen Jahren in
            der Form "HaspaJoker" in den Varianten "smart", "comfort" und
            "premium".
          </h4>
          <h4>
            Damit verbunden sind unter anderem viele Shopping-Vergünstigen in
            und um Hamburg.
          </h4>

          <div className="centerImgSmaller">
            <img src="/assets/img/HaspaJoker.png"></img>
          </div>
          <h4>
            Zum Beispiel unsere 3 ausgewählten Wasch- und Pflege-Pakete "Joker
            Basis", "Joker Lack" und "Joker Komplett".
          </h4>

          <h4>
            Über ein HaspaJoker-Girokonto können Sie sich z.B. bequem online auf
            "
            <a
              style={{ textDecoration: "none", color: "blue" }}
              href="https://www.haspajoker.de/partner/best-carwash"
            >
              haspa.de
            </a>
            " informieren.
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HaspaJoker;
