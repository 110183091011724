import "../sub.css";
import "../Nord/Nord.css";
import { Player } from "video-react";
import "video-react/dist/video-react.css";

const CityTest = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea">
          <div className="cityDesktop special">
            <div>
              <div>
                <div className="pageContentWrapper">
                  <h2 className="lightHeader">BEST CARWASH HH-CITY <br/>
                    V.I.P. CAR COSMETIC</h2>
                  <h4 className="subTextBlock light">
                    Die BEST CARWASH-Gruppe verfügt über
                    jahrzehntelange Erfahrung im Bereich
                    CarCosmetic am Standort Hamburg:
                    Lacke polieren & wachsen, Reinigen
                    des Innenraums - von der schnellen
                    Express-Innenreinigung bis hin zur
                    perfekten Komplett-Aufbereitung.
                    <br />
                  </h4>
                </div>

                <div className={"yellowBtn"}>
                  <a href={"tel:+49040230720"}>
                    TERMIN-TELEFON | 040 23 07 20
                  </a>
                </div>

                <div>
                  <h4 className={"light"}>Sprechen Sie uns gerne direkt vor Ort an,
                    um sich individuell beraten zu lassen <br/>- oder
                    rufen Sie uns direkt für Ihren Termin an.</h4>
                </div>
                <br />

                <div className="pageContentWrapper">
                  <h2 className="lightHeader">
                    AKTUELLE SPAR-AKTION
                  </h2>
                  <img src="/assets/BEST_2.png"></img>
                  <p className={"infoText"}>
                    HINWEIS<br/>
                    Die 15-Euro-Angebote gelten für normal
                    verschmutzte Fahrzeuge - und nur in Verbindung mit unseren BEST-Wäschen
                  </p>
                  <h2 className="lightHeader below">
                    LOUNGE & FREE COFFEE
                  </h2>
                  <h4
                    className="subTextBlock light"
                    style={{ marginBottom: "20px", marginTop: "-10px" }}
                  >
                    Während vieler Reinigungen können Sie
                    direkt in der Lounge bei einem frisch gebrühten
                    Kaffee warten. Wir bieten Ihnen auf Wunsch
                    aber auch einen Hol- und Bring-Service.
                  </h4>
                  <img src="/assets/Special/FOTO VOM HOF.png" className={"smallDividerImg"}></img>
                  <img src="/assets/SPALDING_FOTO_HALLE.jpg" className={"smallDividerImg"}></img>
                  <p className={"infoText"}>
                    Seit über 20 Jahren in der Spaldingstraße in hamburg-City. <br/>
                    Die BEST-Profis für Fahrzeugpflege und von Express bis Komplett-Aufbereitung
                  </p>

                  <br/>

                  <h2 className="lightHeader below">
                    UNSER LEISTUNGSSPEKTRUM
                  </h2>
                  <h4
                      className="subTextBlock light"
                      style={{ marginBottom: "20px", marginTop: "-10px" }}
                  >
                    Und was können wir für Sie tun?
                  </h4>
                  <img src="/assets/Special/SPALDIG_CAR_COSMETIC_4-quer-DESKTOP.png"></img>
                  <h2 className="lightHeader below">
                    BEI UNS STIMMEN
                    PREIS & LEISTUNG
                  </h2>
                  <h4
                      className="subTextBlock light"
                      style={{ marginBottom: "20px" }}
                  >
                    Unsere aktuellen Preise erfahren Sie vor Ort.
                    Meistens ist eine Kalkulation erst am konkreten
                    Fahrzeug möglich, indem wir Fahrzeug und
                    Verschmutzungsgrad mit einbeziehen.
                    Unsere zufriedenen Kunden bestätigen uns
                    seit Jahrzehnten. dass bei uns Kompetenz
                    Preis und Leistung zusammenpassen.
                    Und was können wir für Sie tun?
                  </h4>
                  <br/>

                  <h2 className="lightHeader below">
                    BEST HANDWASH
                  </h2>
                  <img src="/assets/Special/FOTO OBEN.png"></img>
                  <h4
                      className="subTextBlock light"
                      style={{ marginBottom: "20px", marginTop: "-10px" }}
                  >
                    Als besondere Dienstleistung bieten wir Ihnen
                    bei BEST CARWASH CITY in der Spaldingstraße
                    mit BEST HANDWASH eine echte Handwäsche an. <br/><br/>
                    Mit besonders weichen Schwämen, viel
                    Sachverstand und jahrelanger Erfahrung
                    waschen wir Ihr Auto von Hand. <br/> <br/>
                    Noch schonender als mit einer Handwäsche
                    Ihres Fahrzeugs wie BEST HANDWASH geht
                    es für Felgen und Lack nicht. <br/> <br/>
                    Nähere Informationen direkt von unserem
                    Pflegepersonal in der Halle vor Ort.
                  </h4>

                  <img src="/assets/Special/VIGNETTE.png" style={{maxWidth: "200px"}}></img>
                  <p className={"infoText black"}>
                    Der Profi weiß: Das Auto von Hand zu waschen, ist das Beste, <br/>
                    was Sie Ihrem Fahrzeug geben können. Typisch BEST HANDWASH.
                  </p>

                  <br/>

                  <h2 className="lightHeader below">
                    LEASING-RÜCKLÄUFER
                    BIS ZU 80% SPAREN <br/>
                    DANK SMART REPAIR
                  </h2>
                  <h4
                      className="subTextBlock light"
                      style={{ marginBottom: "20px" }}
                  >
                    Haben Sie Bedenken, dass Ihr Leasing-Fahrzeug
                    bei der Rückgabe zu viele Mängel aufweist,
                    die sich der Vertragshändler teuer bezahlen
                    lässt?
                    Wir begutachten Ihr Leasing-Fahrzeug vor
                    der Rückgabe und beraten Sie individuell.
                    So schlagen Sie möglichen Nachforderungen
                    ein günstiges Schnäppchen.
                  </h4>
                  <br/>
                  <h2 className="lightHeader below">
                    SMART REPAIR IST UNSER DING
                  </h2>
                  <h4
                      className="subTextBlock light"
                      style={{ marginBottom: "20px" }}
                  >
                    - Park-Rempler <br/>
                    - Dellenbeseitiqung <br/>
                    - Ausbeultechnik mit Lackschutz <br/>
                    - Lackschäden entfernen <br/>
                    - Kratzer entfernen z.B. mit Airbrush-Technik<br/>
                    - Stoßstangen austauschen <br/>
                    - Felgen austauschen etc.<br/>
                    <br/><br/>
                    Sprechen Sie uns gerne an <br/>
                    Wir beraten Sie individuell
                  </h4>

                  <div className={"yellowBtn"}>
                    <a href={"tel:+49040230720"}>
                      TERMIN-TELEFON | 040 23 07 20
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="backbtnWrapper">
              <a className="backBtn" href="/Users/ticianstreifeneder/WebstormProjects/BESTCarWashSRC/public">
                <img src="/assets/img/mobile/backBtn.png"></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityTest;
