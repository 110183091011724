import "../../../subPages/sub.css";
import "../../subPages/City/City.css";
import "video-react/dist/video-react.css";

const CityPresseMobil = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea">
          <div className="cityMobil">
            <h2>Pressearchiv</h2>
            <video
              controls
              autoplay="autoplay"
              muted="muted"
              loop="loop"
              playsinline="playsinline"
              preload="metadata"
            >
              <source src="/assets/Videos/bcwMain.mp4" type="video/mp4" />
            </video>

            <div className="pressegrid">
              <a href="/assets/pdf/ARTIKEL_ALSTER-MAGAZIN.pdf">
                <img src="/assets/Presse/Alster_Desktop.png"></img>
              </a>
              <a href="/assets/pdf/ARTIKEL_MOPO_2.pdf">
                <img src="/assets/Presse/Mobo_mobil.png"></img>
              </a>
              <a href="/assets/Presse/Artikel_City.png">
                <img src="/assets/Presse/Artikel_City.png"></img>
              </a>
            </div>
          </div>
          <div className="backbtnWrapper">
            <a className="backBtn" href="/">
              <img src="/assets/img/mobile/backBtn.png"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityPresseMobil;
