import "./TextPage.css";

const Kaffee = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage kaffee">
          <h2>Kostenloser Kaffee</h2>

          <h4>
            Bei BEST CARWASH steigen Sie als Kunde während des Waschens aus.
          </h4>

          <img src="/assets/img/kaffeebecher.png"></img>

          <h4>
            Freuen Sie sich auf freundliches Service-Personal, einen frisch
            gebrühten Kaffee, aktuelle Tageszeitungen und Zeitschriften –
            <br className="notOnMobile"></br>sowie jede Menge weitere kostenlose
            Service-Angebote. Fachkundige Beratung gibt es auf Wunsch obendrauf.
          </h4>

          <img src="/assets/img/Kaffee.png"></img>

          <h4>
            HINWEIS: <br></br>Auf unserer Express-Anlage „Hamburg City“ in der
            Spaldingstraße bleiben Sie während des Waschens sitzen.{" "}
            <br className="notOnMobile"></br>Ihren frischen Kaffee bekommen Sie
            von uns hier im Pappbecher "to-go".
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Kaffee;
