import "./ServiceTile.css";

const ServiceTile = (props) => {
  return (
    <>
      {!props.onClick && (
        <a style={{ textDecoration: "none" }} href={props.url}>
          <div className={`serviceTile ${props.type}`}>
            <img src={props.src}></img>
            <h4>
              {props.l1} <br />
              {props.l2}
            </h4>
          </div>
        </a>
      )}

      {props.onClick && (
        <div
          className={`serviceTile ${props.type}`}
          onClick={() => {
            props.onClick(true);
          }}
        >
          <img src={props.src}></img>
          <h4>
            {props.l1} <br />
            {props.l2}
          </h4>
        </div>
      )}
    </>
  );
};

export default ServiceTile;
