import "./TextPage.css";

const Pflege = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage">
          <h2>Unsere BEST Pflege</h2>

          <div className="budniMobileBreak noSpacing">
            <h4>Beim Auto ist es wie bei uns Menschen: </h4>
            <h4>Auch die inneren Werte müssen stimmen.</h4>
          </div>

          <h4>
            Darum bieten wir bei BEST CARWASH Ihnen nicht nur die perfekte
            Fahrzeugwäsche <br className="hideOnDesktop"></br>von außen,
            <br className="notOnMobile"></br>sondern verfügen auch über
            langjährige Erfahrungen im Bereich Pflege & Aufbereitung von
            Polstern und Armaturen.
          </h4>

          <h4>
            Auch für Lack-Polituren und Lack-Reinigungen sind wir Ihr
            kompetenter Ansprechpartner.
          </h4>

          <img src="/assets/img/Pflege.png"></img>

          <h4>
            HINWEIS: <br />
            Am Standort Hamburg Nord in Poppenbüttel bieten wir nur eine Auswahl
            unseres Pflege-Programms an. <br className="notOnMobile"></br>Bitte
            erkundigen Sie sich im Vorweg.
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Pflege;
