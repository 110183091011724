import "./TextPage.css";

const Datenschutz = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage lawText">
          <h2>Datenschutzhinweise Website</h2>
          <h4>
            <strong>Gegenstand</strong>
            <br />
            <br />
            Nachfolgend möchten wir Sie darüber informieren, welche
            personenbezogenen Daten bei Ihrem Besuch unserer Website und den
            nachfolgend beschriebenen Vorgängen verarbeitet werden.
            <br />
            <br />
            Personenbezogene Daten (nachfolgend „Daten“) sind alle Daten, die
            auf Sie persönlich beziehbar sind, z.B. Ihr Name, Ihre
            E-Mail-Adresse und Ihre Nutzung unserer Website.
            <br />
            <br />
            <br />
            <strong>Verantwortlicher</strong>
            <br />
            <br />
            Gemeinsam Verantwortliche im Sinne der Datenschutz-Grundverordnung
            (DSGVO, für Verarbeitungen auf der Website, sind die <br />
            <br />
            Babendreier CARWASH GmbH & Co. KG, Poppenbüttler Weg 94, 22339
            Hamburg, E-Mail-Adresse: poppenbuettel@bestcarwash-hamburg.de;{" "}
            <br />
            <br />
            CWH CARWASH Hamburg GmbH, Osdorfer Landstraße 157, 22549 Hamburg,
            E-Mail-Adresse: info@bestcarwash-osdorf.de;
            <br />
            <br />
            Kessemeier Beteiligungsgesellschaft mbH, Harvestehuder Weg 37, 20149
            Hamburg, city@bestcarwash-hamburg.de.
            <br />
            <br />
            Für Fragen zum Thema Datenschutz können Sie sich über die genannten
            Kontaktdaten direkt an die jeweiligen Verantwortlichen wenden. Den
            Datenschutzbeauftragten für die beiden erstgenannten Standorte,
            erreichen Sie unter poppenbuettel@bestcarwash-hamburg.de
            bzw.info@bestcarwash-osdorf.de oder der jeweiligen Anschrift mit dem
            Zusatz „der Datenschutzbeauftragte“.
            <br />
            <br />
            <br />
            <strong>Browserdaten</strong>
            <br />
            <br />
            Sobald Sie unsere Website aufrufen, erhebt unser Webserver, auf dem
            unsere Website liegt, automatisch die folgenden von Ihrem Browser
            übermittelten Daten:
            <br />- IP-Adresse Ihres Endgerätes
            <br />- Datum und Uhrzeit der Anfrage
            <br />- Inhalt der Anforderung (konkrete Seite)
            <br />- Zugriffsstatus und übertragene Datenmenge
            <br />- Produkt- und Versionsinformationen Ihres Browsers
            <br />- Betriebssystem Ihres Endgerätes
            <br />- von welcher Website aus der Zugriff auf unsere Website
            erfolgte
            <br />
            <br />
            Diese Daten sind für uns technisch erforderlich, um Ihnen unsere
            Website anzuzeigen und um die Stabilität und Sicherheit unserer
            Website zu gewährleisten.
            <br />
            <br />
            Die IP-Adresse Ihres Endgerätes wird dabei nur für die Zeit Ihrer
            Nutzung der Website gespeichert und im Anschluss daran unverzüglich
            gelöscht oder durch Kürzung anonymisiert.
            <br />
            <br />
            Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.
            <br />
            <br />
            <br />
            <strong>Funktionelle Cookies</strong>
            <br />
            <br />
            Wir setzen Cookies ein, um die Nutzung unserer Website komfortabel
            für Sie zu gestalten.
            <br />
            <br />
            Cookies sind kleine Textdateien, die im Rahmen Ihres Besuchs unserer
            Website von unserem Webserver an Ihren Browser gesandt und von
            diesem auf Ihrem Rechner für einen späteren Abruf vorgehalten
            werden. Die Cookies ermöglichen die Wiedererkennung Ihres
            Internetbrowsers.
            <br />
            <br />
            Wir setzen Cookies ein, um Ihre Voreinstellungen verfügbar zu
            machen, um Ihre persönlichen Daten bei zukünftigen Interaktionen mit
            unserer Website in die Eingabemaske einzutragen zu lassen oder um
            Ihre Autorisierung sicherzustellen.
            <br />
            <br />
            Session-Cookies werden automatisiert gelöscht, wenn Sie den Browser
            schließen. Andere Cookies werden automatisiert nach einer
            vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden
            kann. Die genaue Speicherdauer können Sie den Einstellungen Ihres
            Browsers entnehmen.
            <br />
            <br />
            Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
            konfigurieren und z. B. die Annahme von Third-Party-Cookies oder
            allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie dann
            eventuell nicht alle Funktionen dieser Website nutzen können.
            <br />
            <br />
            Sie können die Cookies in den Sicherheitseinstellungen Ihres
            Browsers jederzeit löschen.
            <br />
            <br />
            Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.
            <br />
            <br />
            <br />
            <strong>Ihre Anfrage</strong>
            <br />
            <br />
            Wir bieten Ihnen verschiedene Kontaktmöglichkeiten, z.B. unsere
            Anschrift, unsere E-Mail-Adresse, unsere Telefonnummer, u.U.
            Chatsysteme, die Möglichkeit zu einer Online-Terminvereinbarung und
            unser Kontaktformular.
            <br />
            <br />
            Wenn Sie mit uns Kontakt aufnehmen, nutzen wir die von Ihnen
            mitgeteilten Daten, wie z.B. Ihre E-Mail-Adresse, Ihren Namen und
            den Inhalt Ihrer Anfrage, um Ihre Anfrage zu bearbeiten.
            <br />
            <br />
            In Rahmen der Kommunikation setzen wir u.U. zusätzlich Messenger
            ein. Sie sind jederzeit berechtigt unsere weiteren
            Kommunikationsmittel zu nutzen. Nutzt der Messenger eine
            Ende-zu-Ende-Verschlüsselung, so kann der Messenger-Anbieter nicht
            auf den Nachrichteninhalt zugreifen. Jedoch hat der
            Messenger-Dienstleister u.U. Zugriff auf die Information, dass eine
            Kommunikation stattgefunden hat und welches Gerät genutzt wurde.
            Diese Daten werden aber durch den Messenger-Anbieter verarbeitet,
            lesen Sie hierzu dessen Datenschutzerklärung.
            <br />
            <br />
            Die hierbei anfallenden Daten löschen wir, nachdem die Speicherung
            nicht mehr erforderlich ist, oder schränken die Verarbeitung ein,
            falls gesetzliche Aufbewahrungspflichten bestehen.
            <br />
            <br />
            Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.
            <br />
            <br />
            <br />
            <strong>Bewerbungen</strong>
            <br />
            <br />
            Falls wir auf der Suche nach Mitarbeitern sind, veröffentlichen wir
            Stellenausschreibungen.
            <br />
            <br />
            Um Ihre Bewerbung bearbeiten zu können, benötigen wir bestimmte
            Daten von Ihnen.
            <br />
            <br />
            Neben Ihrem Namen und Ihren Kontaktdaten brauchen und verarbeiten
            wir auch Ihre sonstigen Bewerberdaten, z.B. Ihr Bewerbungsschreiben,
            Lebenslauf, Zeugnisse oder Gesprächsnotizen.
            <br />
            <br />
            Die im Bewerbungsverfahren anfallenden Daten löschen wir, wenn die
            Daten für den Zweck der Bewerbung nicht mehr gebraucht werden. Dies
            ist nach einer Frist von höchstens sechs Monaten nach Ende des
            Bewerbungsverfahrens der Fall, sollte eine Anstellung nicht
            erfolgen. Dies gilt nicht, sofern gesetzliche Bestimmungen einer
            Löschung entgegenstehen, die Daten zum Zwecke der Beweisführung
            erforderlich sind oder Sie einer längeren Speicherdauer ausdrücklich
            zugestimmt haben.
            <br />
            <br />
            Falls wir Sie um Einwilligung bitten, z.B. in die längere
            Speicherung Ihrer Daten, ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1
            lit. a DSGVO, § 26 Abs. 2 BDSG. Ansonsten ist die Rechtsgrundlage
            Art. 6 Abs. 1 S. 1 lit. b DSGVO, § 26 BDSG.
            <br />
            <br />
            <br />
            <strong>Geschäftsbeziehung</strong>
            <br />
            <br />
            Für das Zustandekommen wie auch für die Durchführung eines Vertrags
            über die von uns oder von Ihnen angebotenen Leistungen benötigen wir
            personenbezogene Daten.
            <br />
            <br />
            Im Rahmen der Anbahnung bzw. der Durchführung des Vertrags müssen
            Sie diejenigen personenbezogenen Daten bereitstellen, die für die
            Begründung, Durchführung und Beendigung des Vertragsverhältnisses
            und die Erfüllung der damit verbundenen vertraglichen Pflichten
            erforderlich sind. Wir verarbeiten Ihre Daten, um den Vertrag mit
            Ihnen sowie um bestehende gesetzliche Vorgaben, z.B. handels- oder
            steuerrechtlicher Natur, zu erfüllen. Dies kann eine Weitergabe von
            Daten an Subunternehmer, Zahlungsdienstleister oder Behörden
            beinhalten.
            <br />
            <br />
            Wir sind aufgrund handels- und steuerrechtlicher Vorgaben
            verpflichtet, die Vertragsdaten für zehn Jahre zu speichern.
            Allerdings nehmen wir nach zwei Jahren eine Einschränkung der
            Verarbeitung vor, d.h. Ihre Daten werden nur zur Einhaltung der
            gesetzlichen Verpflichtungen eingesetzt.
            <br />
            <br />
            Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.
            <br />
            <br />
            <br />
            <strong>Bestandskundenwerbung</strong>
            <br />
            <br />
            Sollten Sie Kunde bei uns werden, können wir Ihre in diesem
            Zusammenhang erhaltene E-Mail-Adresse nutzen, um Ihnen Werbung für
            ähnliche Waren oder Dienstleistungen zukommen zu lassen.
            <br />
            <br />
            Sie können dieser Werbung jederzeit widersprechen, insbesondere
            indem Sie uns über die im Impressum angegebenen Kontaktmöglichkeiten
            hierüber informieren. Auch falls wir Ihnen für einen Zeitraum von
            zwei Jahren keine Werbung senden, sperren wir Ihre E-Mail-Adresse
            für die Zusendung von Werbung. Für die Speicherung Ihrer
            E-Mail-Adresse an sich gelten die Ausführungen zu den handels- und
            steuerrechtlichen Vorgaben im Zusammenhang mit Ihrem Kauf bei uns.
            <br />
            <br />
            Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO i.V.m. § 7
            Abs. 3 UWG.
            <br />
            <br />
            <br />
            <strong>Bewertungen</strong>
            <br />
            <br />
            Sie haben die Möglichkeit uns und unseren Service zu bewerten. Dafür
            müssen Sie eine E-Mail an bewertung@bestcarwash-hamburg.de senden.
            Neben der E-Mail-Adresse benötigen wir einen Namen, unter dem die
            Bewertung veröffentlicht werden soll. Den Namen können Sie uns,
            zusammen mit Ihrer Bewertung, in der E-Mail mitteilen. Bevor eine
            Bewertung veröffentlich wird, werden wir jedoch Kontakt zu Ihnen
            aufnehmen und uns vergewissern, dass Sie mit der Veröffentlichung
            einverstanden sind.
            <br />
            <br />
            Wir geben die Bewertungen nach einer Überprüfung frei. Dabei
            behalten wir uns vor von einer Freischaltung abzusehen, wenn die
            Bewertung keinen Bezug zum jeweiligen Produkt oder beleidigenden
            Inhalt hat.
            <br />
            <br />
            Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem
            die Speicherung nicht mehr erforderlich ist, oder schränken die
            Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
            <br />
            <br />
            Die Rechtsgrundlage für die Veröffentlichung ist Art. 6 Abs. 1 S. 1
            lit. a DSGVO.
            <br />
            <br />
            <br />
            <strong>Fotos</strong>
            <br />
            <br />
            Sie können uns unter der E-Mail-Adresse: foto@bestcarwash-hamburg.de
            ein Foto mit Bezug zum Thema BEST CARWASH zukommen lassen. Bei Ihrer
            Kontaktaufnahme werden die von Ihnen mitgeteilten Daten
            (E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) gespeichert,
            um mit Ihnen Kontakt aufnehmen zu können, nachdem überprüft wurde,
            ob wir das Foto veröffentlichen.
            <br />
            <br />
            Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem
            die Speicherung nicht mehr erforderlich ist, oder schränken die
            Verarbeitung ein, falls gesetzliche Aufbewahrungsfristen bestehen.
            <br />
            <br />
            Sollten wir ein Interesse an der Veröffentlichung des Fotos haben,
            dann nehmen wir vor der Veröffentlichung Kontakt mit Ihnen auf. Die
            Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. a DSGVO bzw. Art. 6 Abs.
            1 S. 1 lit. b DSGVO.
            <br />
            <br />
            <br />
            <strong> Dienste von Drittanbietern</strong>
            <br />
            <br />
            Wir setzen Dienste von Drittanbietern ein, um unsere Website und
            unsere Angebote zu optimieren.
            <br />
            <br />
            Wenn Sie eine Seite aufrufen, die einen solchen Dienst enthält, baut
            Ihr Browser u.U. eine direkte Verbindung mit den Servern des
            Drittanbieters auf. Der Drittanbieter erhält daher die Information,
            dass Sie bzw. Ihre IP-Adresse die entsprechende Seite unserer
            Website aufgerufen haben.
            <br />
            <br />
            Falls Sie beim Anbieter des Dienstes eingeloggt sind, kann der
            Drittanbieter Ihren Besuch unserer Website Ihrem Konto zuordnen.
            Wenn Sie mit den Diensten interagieren, zum Beispiel einen Button
            betätigen, wird dies von Ihrem Browser direkt an den Drittanbieter
            übermittelt. Wenn Sie nicht möchten, dass ein Drittanbieter, bei dem
            Sie ein Konto unterhalten, über unsere Website Daten über Sie
            erfasst, müssen Sie sich vor Ihrem Besuch unserer Website bei dem
            jeweiligen Konto ausloggen.
            <br />
            <br />
            Teils kommen Cookies oder ähnliche Verfahren zum Einsatz.
            <br />
            <br />
            Cookies sind kleine Textdateien, die im Rahmen Ihres Besuchs unserer
            Website von unserem Webserver an Ihren Browser gesandt und von
            diesem auf Ihrem Rechner für einen späteren Abruf vorgehalten
            werden. Die Cookies ermöglichen die Wiedererkennung Ihres
            Internetbrowsers, u.U. bieten wir Ihnen ein Tool, mit dem Sie eine
            Auswahl zu dem Einsatz von Cookies treffen können, die dann für uns
            gespeichert wird.
            <br />
            <br />
            Zweck und Umfang der Erhebung und Nutzung Ihrer Daten sowie Ihre
            diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz
            Ihrer Privatsphäre durch den Drittanbieter entnehmen Sie bitte den
            Datenschutzerklärungen des jeweiligen Anbieters.
            <br />
            <br />
            Sie können durch die Einstellungen in Ihrem Browser bestimmen, ob
            Cookies gesetzt und abgerufen werden können. Sie können in Ihrem
            Browser etwa das Speichern von Cookies gänzlich deaktivieren oder
            eine ausdrückliche Freigabe je Website aktivieren.
            <br />
            <br />
            <br />
            <strong>Eingebettete Dienste:</strong>
            <br />
            <br />
            Wir betten Dienste von Drittanbietern ein, um unsere Website durch
            zusätzliche Dienste informativer für Sie zu gestalten.
            <br />
            <br />
            Im Einzelnen betten wir Dienste von folgenden Anbietern ein:
            <br /> - Google Fonts - Website: https://fonts.google.com/ ;
            Datenschutzhinweise: https://policies.google.com/privacy
            <br /> - Google Maps - Website:
            https://cloud.google.com/maps-platform ; Datenschutzhinweise:
            https://policies.google.com/privacy
            <br /> - YouTube - Website: https://www.youtube.com/ ;
            Datenschutzhinweise: https://policies.google.com/privacy
            <br />
            <br />
            Falls wir Sie um Einwilligung für die Nutzung der Dienste bitten,
            ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. a DSGVO. Ansonsten
            ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f DSGVO.
            <br />
            <br />
            <br />
            <strong>Dienstleister</strong>
            <br />
            <br />
            Soweit wir Dienstleister einsetzen, die im Rahmen einer sog.
            Auftragsverarbeitung nach Art. 28 DSGVO für uns tätig werden, haben
            wir mit diesen einen entsprechenden Vertrag geschlossen.
            <br />
            <br />
            Soweit wir Dienstleister in sog. Drittländer außerhalb der
            Europäischen Union bzw. des Europäischen Wirtschaftsraums einsetzen,
            lassen wir Ihre Daten nur in Drittländern mit einem durch die
            EU-Kommission geprüften Datenschutzniveau, auf Grundlage besonderer
            Garantien, wie z.B. vertraglicher Verpflichtung durch sog.
            Standardschutzklauseln der EU-Kommission, verarbeiten.
            <br />
            <br />
            Nähere Informationen stellen wir Ihnen auf Anfrage gern bereit.
            <br />
            <br />
            <br />
            <strong>Auftritte in sozialen Medien</strong>
            <br />
            <br />
            Wir betreiben Auftritte in sozialen Medien, um mit unseren Kunden,
            Interessenten und Nutzern zu kommunizieren und über unsere
            Leistungen zu informieren. In der Regel werden Cookies auf den
            Rechnern der Nutzer gespeichert. Bei Cookies handelt es sich um
            kleine Textdateien, die auf der Festplatte dem verwendeten Browser
            zugeordnet gespeichert werden und durch welche der Stelle, die den
            Cookie setzt (hier durch das soziale Netzwerk), bestimmte
            Informationen zufließen. <br />
            <br />
            Die Cookies ermöglichen die Erstellung von Statistiken über die
            Nutzung eines Auftritts in den sozialen Netzwerken. Ferner werden
            die Daten der Nutzer regelmäßig auch für Werbezwecke genutzt, indem
            Nutzungsprofile (insbesondere der angemeldeten Nutzer) über das
            Nutzungsverhalten erstellt und für die Schaltung von passenden
            Werbeanzeigen genutzt werden.
            <br />
            <br />
            Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser
            berechtigtes Interesse ist die umfassende und optimierte Information
            der Nutzer. Wir weisen darauf hin, dass nur das jeweilige soziale
            Netzwerk vollen Zugriff auf die gesamten Daten hat und daher ein
            effektiver Auskunftsanspruch direkt beim sozialen Netzwerk gestellt
            werden sollte.
            <br />
            <br />
            Wir haben mit Facebook Ireland einen Vertrag über die gemeinsame
            Verantwortlichkeit geschlossen. Informationen u.a. zu Art, Umfang
            und Zwecken der Verarbeitung und der Wahrnehmung Ihrer Rechte finden
            Sie unter https://www.facebook.com/about/privacy und in den
            Nutzungsbedingungen zu „umfassten Produkten“ unter
            https://de-de.facebook.com/legal/commercial_terms. <br />
            <br />
            <br />
            <br />
            <br />
            <strong>Ihre Rechte</strong>
            <br />
            <br />
            Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
            betreffenden Daten:
            <br /> - Recht auf Auskunft
            <br /> - Recht auf Berichtigung oder Löschung
            <br /> - Recht auf Einschränkung der Verarbeitung
            <br /> - Recht auf Widerspruch gegen die Verarbeitung
            <br /> - Recht auf Datenübertragbarkeit
            <br /> - Recht auf Beschwerde bei einer Datenschutz-Aufsichtsbehörde{" "}
            <br />
            <br />
            Falls Sie uns eine Einwilligung zur Verarbeitung Ihrer Daten erteilt
            haben, können Sie diese jederzeit mit Wirkung für die Zukunft
            widerrufen.
            <br />
            <br />
            Sie können Direktwerbung jederzeit widersprechen. Falls Ihre
            besondere Situation dies erfordert, können Sie jederzeit auch
            Verarbeitungen aufgrund von Art. 6 Abs. 1 S. 1 lit. f DSGVO
            widersprechen.
            <br />
            <br />
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Datenschutz;
