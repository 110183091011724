import "../../../subPages/sub.css";
import "./City.css";
import "../mobileSubStyle.css";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import { useEffect, useRef } from "react";

const City = () => {
  return (
    <div className="subContainer">
      <div className="subContentWrapper">
        <div className="subContentArea mobileCity">
          <img
              src="/assets/img/City_banner.png"
              style={{width: "100%"}}
          ></img>
          <img
              src="/assets/Hintergrund.png"
              style={{marginTop: "20px", width: "100%"}}
              onClick={()=>{window.location.href="/city/carcosmetic"}}
          ></img>
          <br/>
          <br/>
          <div>
            <div>
              <div className="mobileHeaderSegment">
                <h2>
                  BEST CARWASH CITY <br />
                  SPALDINGSTRASSE
                </h2>
                <div>
                  <img
                    className="sm"
                    src="/assets/img/facebook_einzeln.png"
                    onClick={() => {
                      window.location.href =
                        "https://www.facebook.com/bestcarwashcity/";
                    }}
                  ></img>
                  <img
                    className="sm"
                    src="/assets/img/instaEinzeln.png"
                    onClick={() => {
                      window.location.href =
                        "https://www.instagram.com/bestcarwashcity/?hl=de";
                    }}
                  ></img>
                </div>
              </div>
              <div className="videoWrapper">
                {/* <Player
                  playsInline
                  poster="/assets/poster.png"
                  src="/assets/Videos/trailer_city.mp4"
                /> */}
                <video
                  controls
                  autoplay="autoplay"
                  muted="muted"
                  loop="loop"
                  playsinline="playsinline"
                  preload="metadata"
                >
                  <source
                    src="/assets/Videos/trailer_city.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="pageContentWrapper">
                <h2>
                  HAMBURGS <br />
                  LÄNGSTE SCHAUMPARTY
                </h2>
                <h3 className="smallTitle">BEST CARWASH CITY</h3>
                <h4 className="subTextBlock">
                  Auf Hamburgs längster Schaumparty <br />
                  in der Spaldingstraße in Hamburg City <br /> sorgt modernste
                  Technik für ein perfektes Waschergebnis. <br /> Der richtige
                  Sound, extra viel Schaum <br />
                  und gaaanz viel buntes LED-Licht schaffen echtes Disco
                  Feeling. <br />
                  Und das Beste: <br />
                  Unser Türsteher lässt fast jeden rein. <br />
                  Welcome to the Club!
                </h4>
              </div>

              <div className="greyInfoBox">
                <h5>
                  23 kostenlose Saugerplätze <br />
                  mit Party-Sound
                </h5>
                <h5>Mattenreinigungsgerät</h5>
                <h5>
                  bunte LED-Effekte für <br />
                  echtes Disco-Feeling
                </h5>
                <h5>Pflegetuch-Automat</h5>
                <h5>Handtuch-Tausch-Service</h5>
                <h5>kostenloser Coffee-to-go</h5>
              </div>

              <div>
                <h4>BEST CARWASH CITY</h4>
                <h4>Spaldingstraße 105</h4>
                <h4>20097 Hamburg</h4>
              </div>
              <div>
                <h4>
                  <a style={{ color: "blue" }} href="tel:040230720">
                    Tel | 040-23 07 20
                  </a>
                </h4>
                <h4>
                  <a
                    style={{ color: "blue" }}
                    href="mailto:city@bestcarwash-hamburg.de"
                  >
                    Mail | city@bestcarwash-hamburg.de
                  </a>
                </h4>
              </div>
              <div>
                <h4>
                  Öffnungszeiten <br /> MO-FR 8:00-19:00 <br /> SA 8:00-17:00
                  <br /> SO 13:00-18:00 <br /> An gesetzlichen Feiertagen
                  geschlossen
                </h4>
              </div>
            </div>
            <img
              className="imgGallery"
              src="/assets/img/FOTO_ANLAGE_HH-CITY.png"
            ></img>
            <img className="imgGallery" src="/assets/img/CarWashImg.png"></img>
            <img className="imgGallery" src="/assets/img/automat.png"></img>
          </div>

          <div className="pageContentWrapper">
            <img src="/assets/img/BEST BUSINESS.png"></img>
            <h2>
              FLOTTENSERVICE <br />
              FÜR FIRMENKUNDEN
            </h2>
            <div className="greyInfoBox">
              <h5>WASCHEN & INNENREINIGUNG</h5>
              <h5>ALLE VORTEILE AUF EINEN BLICK</h5>
              <h5>persönlicher Ansprechpartner</h5>
              <h5>
                Wasch- & Pflege-Pakete
                <br />
                individuell auf die Anforderungen <br />
                Ihrer Firmenflotte zugeschnitten
              </h5>
              <h5>
                Unser Vor-Ort-Service sorgt <br />
                für einen reibungslosen Ablauf
              </h5>
              <h5>
                Gerne erstellen wir Ihnen <br />
                ein individuelles Angebot
              </h5>
            </div>
          </div>

          <div className="pageContentWrapper">
            <h2>VIP-SERVICE</h2>
            <img src="/assets/img/wechsler.png"></img>
            <div className="loungeWrapper">
              <h4>
                Lounge mit Free Coffee & Kaltgetränken entspannt warten, während
                wir Ihr Auto auf Hochglanz bringen oder von innen aufbereiten
              </h4>
              <img src="/assets/img/LOUNGE.png"></img>
            </div>
          </div>

          <div className="pageContentWrapper">
            <h2>Passt</h2>
            <img className="contentHeaderImg" src="/assets/img/FORD.png"></img>
            <h6>
              breit, hoch, flach... <br />
              <br />
              Bei uns passt (fast) jeder Fahrzeugtyp rein. Erkundigen Sie sich
              gerne vor Befahren der Waschanlage nach Ihrem individuellen
              Fahrzeugtyp
            </h6>
          </div>

          <div className="pageContentWrapper">
            <h2>LASS SIE DOCH SCHREIBEN</h2>
            <img
              className="contentHeaderImg"
              src="/assets/img/zeitungsartikel.png"
            ></img>
            <div className="iconText">
              <img src="/assets/img/presse_icon.png"></img>
              Zum Presse-Archiv geht's <a href="/cpresse">HIER</a>
            </div>
          </div>

          <div className="pageContentWrapper">
            <h2>HELLO & GOOD-BYE</h2>
            <img
              className="contentHeaderImg"
              src="/assets/img/bottom_2.png"
            ></img>
            <img
              className="contentHeaderImg"
              src="/assets/img/bottom_1.png"
            ></img>

            <div className="iconText">
              <img
                src="/assets/img/foto.png"
                className="bottomImgTextImg"
              ></img>
              <a href="/carchiv">HIER</a> geht's zur Fotogalerie
            </div>
          </div>

          <br/>
          <a className={"backHomeBtn"} href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
      <img className="pfeilDown" src="/assets/img/mobile/pfeil.png"></img>
    </div>
  );
};

export default City;
