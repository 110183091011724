import "./mobileLanding.css";

//Dependancies
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

//Modules
import ServiceTile from "../Global/ServiceTile/ServiceTile";
import MobileHome from "./mobileHome/MobileHome";

//Pages
import City from "./subPages/City/City";
import West from "./subPages/West/West";
import Nord from "./subPages/Nord/Nord";
import BestFriends from "./subPages/BestFriends/BestFriends";
import CityPresseMobil from "./subPages/City/CityPresseMobil";
import CityFotoArchiv from "../subPages/City/CityFotoArchiv";

//subPages
import TextPage from "../subPages/TextPage/TextPage";
import Waesche from "../subPages/TextPage/Waesche";
import About from "../subPages/TextPage/About";
import Budni from "../subPages/TextPage/Budni";
import HaspaJoker from "../subPages/TextPage/HaspaJoker";
import Kaffee from "../subPages/TextPage/Kaffee";
import Saugen from "../subPages/TextPage/Saugen";
import Stempel from "../subPages/TextPage/Stempel";
import Pflege from "../subPages/TextPage/Pflege";
import Abmessungen from "../subPages/TextPage/Abmessungen";
import Impressum from "../subPages/TextPage/Impressum";
import Datenschutz from "../subPages/TextPage/Datenschutz";
import Jobs from "../subPages/TextPage/Jobs";
import Öffnungszeiten from "../subPages/TextPage/Öffnungszeiten";
import Facebook from "../subPages/TextPage/Facebook";
import CityTest from "./subPages/City/CityTest";

const MobileLanding = (props) => {
  return (
    <Router>
      <div className="gridContainerMobile">
        <div
          className="mobileHeader"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/assets/img/mobile/BETONBALKEN_OBEN_QUER.svg" +
              ")",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <h3>ERLEBNISWELT</h3>
          <h2>BEST CARWASH HAMBURG</h2>
          <div>
            <h4>3 MAL ANDERS</h4>
            <h4>|</h4>
            <h4>3 MAL BEST</h4>
          </div>
        </div>
        <div className="mobileRight mobileSide">
          <ServiceTile
            l1=""
            src="/assets/img/mobile/arrowDown.png"
            type="rightLogo"
            url="/"
          />
          <ServiceTile
            l1="Goodies"
            l2="für Buddies"
            src="/assets/img/FRAME_APP_ICON_8.svg"
            url="/friends"
          />
          <ServiceTile
            l1="Follow us"
            l2="now"
            src="/assets/img/FB_IG.png"
            url="/facebook"
          />
          <ServiceTile
            l1="kostenlos"
            l2="saugen"
            src="/assets/img/FRAME_APP_ICON_5.svg"
            url="/saugen"
          />
          <ServiceTile
            l1="kostenloser"
            l2="Kaffee"
            src="/assets/img/FRAME_APP_ICON_6.svg"
            url="/kaffee"
          />

          <img
            src="/assets/img/mobile/TREPPE_GESTRECKT_RECHTS.svg"
            className="mobileStairs"
          ></img>
        </div>
        <div
          className="mobilePillar"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/assets/img/mobile/BETONSAEULE_MITTE.svg" +
              ")",
          }}
        >
          <Switch>
            <Route path="/cpresse">
              <CityPresseMobil />
            </Route>
            <Route path="/carchiv">
              <CityFotoArchiv />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/öffnungszeiten">
              <Öffnungszeiten />
            </Route>
            <Route path="/jobs">
              <Jobs />
            </Route>
            <Route path="/impressum">
              <Impressum />
            </Route>
            <Route path="/datenschutz">
              <Datenschutz />
            </Route>
            <Route path="/friends">
              <BestFriends />
            </Route>
            <Route path="/abmessungen">
              <Abmessungen />
            </Route>
            <Route path="/facebook">
              <Facebook />
            </Route>
            <Route path="/budni">
              <Budni />
            </Route>
            <Route path="/haspa">
              <HaspaJoker />
            </Route>
            <Route path="/pflege">
              <Pflege />
            </Route>
            <Route path="/stempel">
              <Stempel />
            </Route>
            <Route path="/kaffee">
              <Kaffee />
            </Route>
            <Route path="/saugen">
              <Saugen />
            </Route>
            <Route path="/waesche">
              <Waesche />
            </Route>
            <Route path="/sub">
              <TextPage />
            </Route>
            <Route path="/nord">
              <Nord />
            </Route>
            <Route path="/city" exact>
              <City />
            </Route>
            <Route path="/city/carcosmetic">
              <CityTest />
            </Route>
            <Route path="/west">
              <West />
            </Route>

            <Route path="/">
              <MobileHome />
            </Route>
          </Switch>
        </div>
        <div className="mobileLeft mobileSide">
          <ServiceTile
            l1="best-hh.de"
            src="/assets/img/BEST_LOGO_OBEN_LINKS_ECKE.svg"
            type="leftLogo"
            url="/"
          />
          <ServiceTile
            l1="HaspaJoker"
            l2="Wäschen"
            src="/assets/img/FRAME_APP_ICON_1.svg"
            url="/haspa"
          />
          <ServiceTile
            l1="BudniCard"
            l2="Wäsche"
            src="/assets/img/FRAME_APP_ICON_2.svg"
            url="/budni"
          />
          <ServiceTile
            l1="BEST"
            l2="Wäschen"
            src="/assets/img/FRAME_APP_ICON_3.svg"
            url="/waesche"
          />
          <ServiceTile
            l1="BEST"
            l2="Pflege"
            src="/assets/img/FRAME_APP_ICON_4.svg"
            url="/pflege"
          />
          <img
            src="/assets/img/mobile/TREPPE_GESTRECKT_LINKS.svg"
            className="mobileStairs"
          ></img>
        </div>
        <div className="mobileFooter">
          <div
            className="bestFriendsFooter"
            onClick={() => {
              window.location.href = "/friends";
            }}
          >
            <img src="/assets/img/mobile/bfmobile.png"></img>
            Hier gibt's Freundschaftsgeschenke
          </div>
          <div
            className="bestFriendsFooter"
            onClick={() => {
              props.setPopUp(true);
            }}
          >
            <img src="/assets/img/movieMobile.png"></img>
            Hier geht's zum Film...
          </div>
          <div
            className="bestFriendsFooter"
            onClick={() => {
              window.location.href = "/nord";
            }}
          >
            <img src="/assets/img/BEST BUSINESS.png"></img>
            <span>
              <a href="/nord">Poppenbüttel</a>{" "}
              <span style={{ margin: "0px 5px" }}>|</span>{" "}
              <a href="/city">City</a>
            </span>
          </div>
          <h4>
            BARZAHLUNG <span className="textDivider"> | </span> ODER BEQUEM &
            KONTAKTLOS
          </h4>
          <div>
            <img src="/assets/img/mobile/KREDITKARTEN_LOGOS_ALLE.png"></img>
            <div className="FooterSubIcons">
              <div>
                <img
                  className="first"
                  src="/assets/img/mobile/DKV-LOGO.svg"
                ></img>
              </div>
              <div>
                <img src="/assets/img/mobile/UTA-LOGO.svg"></img>
              </div>
              <h4>UTA nur in City</h4>
            </div>
          </div>

          <h5>
            <a href="/stempel">
              Für Stammkunden <span className="textDivider"> | </span> BEST Card
              & Stempelkarte & 10er-Blöcke <br></br>
              <a href="/öffnungszeiten">Öffnungszeiten</a>
              <span className="textDivider"> | </span>
              <a href="/abmessungen">Fahrzeugabmessungen</a>
              <br></br>
              <a href="/jobs">Job-Börse</a>
              <span className="textDivider"> | </span>
              <a href="/about">Die BEST CARWASH Gruppe</a>
            </a>
          </h5>

          <div className="mobileLawLinks">
            <a href="/impressum">IMPRESSUM</a>
            <p>|</p>
            <a href="/datenschutz">DATENSCHUTZ</a>
          </div>

          <img
            className="mobileFooterLogo"
            src="/assets/img/mobile/BEST_LOGO_SCHWARZ_OUTLINE_UNTEN_MITTE.svg"
            onClick={() => {
              window.location.href = "/";
            }}
          ></img>
          <p className="mobileFooterText">
            Die Pioniere der textilen Autowäsche
          </p>
        </div>
      </div>
    </Router>
  );
};

export default MobileLanding;
