import "./Landing.css";

//Components
import Home from "../Home/Home";
import Nord from "../../subPages/Nord/Nord";
import City from "../../subPages/City/City";
import West from "../../subPages/West/West";
import TextPage from "../../subPages/TextPage/TextPage";
import Waesche from "../../subPages/TextPage/Waesche";
import About from "../../subPages/TextPage/About";
import Budni from "../../subPages/TextPage/Budni";
import HaspaJoker from "../../subPages/TextPage/HaspaJoker";
import Kaffee from "../../subPages/TextPage/Kaffee";
import Saugen from "../../subPages/TextPage/Saugen";
import Stempel from "../../subPages/TextPage/Stempel";
import Pflege from "../../subPages/TextPage/Pflege";
import Abmessungen from "../../subPages/TextPage/Abmessungen";
import BestFriends from "../../subPages/BestFriends/BestFriends";
import Impressum from "../../subPages/TextPage/Impressum";
import Datenschutz from "../../subPages/TextPage/Datenschutz";
import Jobs from "../../subPages/TextPage/Jobs";
import Öffnungszeiten from "../../subPages/TextPage/Öffnungszeiten";
import Facebook from "../../subPages/TextPage/Facebook";
import CityPresseDesktop from "../../subPages/City/CityPresseDesktop";
import CityFotoArchiv from "../../subPages/City/CityFotoArchiv";

//Dependancies
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CityTest from "../../subPages/City/CityTest";

const Landing = (props) => {
  return (
    <Router>
      <div className="gridContainer">
        <div className="compLogo" onClick={()=>{window.location.href="/"}} style={{cursor: "pointer"}}>
          <a href="/">
            <img src="/assets/img/BEST_LOGO_OBEN_LINKS_ECKE.svg"></img>
            <h3>best-hh.de</h3>
          </a>
        </div>
        <div
          className="gridHeader"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/assets/img/BETONDECKEL_OBEN.svg" +
              ")",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <div>
            <h4>3 MAL ANDERS</h4>
            <h4>|</h4>
            <h4>3 MAL BEST</h4>
          </div>
          <h2>ERLEBNISWELT BEST CARWASH HAMBURG</h2>
        </div>
        <div className="gridCol left">
          <img src="/assets/img/TREPPE_LINKS.svg" className="Stairs"></img>
        </div>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/carchiv">
            <CityFotoArchiv />
          </Route>
          <Route path="/facebook">
            <Facebook />
          </Route>
          <Route path="/öffnungszeiten">
            <Öffnungszeiten />
          </Route>
          <Route path="/jobs">
            <Jobs />
          </Route>
          <Route path="/impressum">
            <Impressum />
          </Route>
          <Route path="/datenschutz">
            <Datenschutz />
          </Route>
          <Route path="/friends">
            <BestFriends />
          </Route>
          <Route path="/abmessungen">
            <Abmessungen />
          </Route>
          <Route path="/budni">
            <Budni />
          </Route>
          <Route path="/haspa">
            <HaspaJoker />
          </Route>
          <Route path="/pflege">
            <Pflege />
          </Route>
          <Route path="/stempel">
            <Stempel />
          </Route>
          <Route path="/kaffee">
            <Kaffee />
          </Route>
          <Route path="/saugen">
            <Saugen />
          </Route>
          <Route path="/waesche">
            <Waesche />
          </Route>
          <Route path="/sub">
            <TextPage />
          </Route>
          <Route path="/nord">
            <Nord />
          </Route>
          <Route path="/city" exact>
            <City />
          </Route>
          <Route path="/city/carcosmetic">
            <CityTest />
          </Route>
          <Route path="/cpresse">
            <CityPresseDesktop />
          </Route>

          <Route path="/west">
            <West />
          </Route>
          <Route path="/">
            <Home setPopUp={props.setPopUp} />
          </Route>
        </Switch>
        <div className="gridCol right">
          <img src="/assets/img/TREPPE_RECHTS.svg" className="Stairs"></img>
        </div>
        <div className="gridFooter">
          <h4>
            SIE HABEN DIE WAHL <span className="textDivider"> | </span>
            BARZAHLUNG ODER BEQUEM & KONTAKTLOS
          </h4>
          <div>
            <img src="/assets/img/mobile/KREDITKARTEN_LOGOS_ALLE.png"></img>
            <div className="footerIcons">
              <div>
                <img
                  className="first"
                  src="/assets/img/mobile/DKV-LOGO.svg"
                ></img>
              </div>
              <div>
                <img src="/assets/img/mobile/UTA-LOGO.svg"></img>
              </div>
              <h4>UTA nur in City</h4>
            </div>
          </div>

          <h5>
            <a href="/stempel">
              Für Stammkunden <span className="textDivider"> | </span> BEST Card
              & Stempelkarte & 10er-Blöcke
            </a>
          </h5>
          <h5>
            <a href="/öffnungszeiten">Öffnungszeiten</a>{" "}
            <span className="textDivider"> | </span>
            <a href="/abmessungen">Fahrzeugabmessungen</a>
            <span className="textDivider"> | </span>
            <a href="/jobs">Job-Börse</a>
            <span className="textDivider"> | </span>
            <a href="/about">Die BEST CARWASH Gruppe</a>
          </h5>

          <div className="lawLinks">
            <a href="/impressum">IMPRESSUM</a>
            <p>|</p>
            <a href="/datenschutz">DATENSCHUTZ</a>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Landing;
