import "./TextPage.css";

const Impressum = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage lawText">
          <h2>Impressum</h2>

          <div className="noSpacing">
            <h4>
              <strong>BEST CARWASH HH-NORD</strong>
            </h4>
            <h4>
              {" "}
              Babendreier CARWASH GmbH & Co. KG
              <br />
              Poppenbütteler Weg 94
              <br />
              22339 Hamburg
              <br />
              <br />
              Tel: 040 / 538 11 61
              <br />
              Mail: poppenbuettel@bestcarwash-hamburg.de
              <br />
              <br />
              Registergericht: Amtsgericht Hamburg
              <br />
              Registernummer: HRA 116576
              <br />
              USt-IdNr. gem. § 27a UStG: DE165575885
              <br />
              <br />
              Persönlich haftende Gesellschafterin:
              <br />
              Babendreier Verwaltungsgesellschaft mbH
              <br />
              Lindenstraße 8<br />
              24539 Neumünster
              <br />
              Registergericht: Amtsgericht Kiel
              <br />
              Registernummer: HRB 15060 KI
              <br />
              Geschäftsführer: Florian Probst
            </h4>
          </div>
          <div className="noSpacing">
            <h4>
              <br />
              <strong>BEST CARWASH HH-WEST</strong>
            </h4>
            <h4>
              CWH CARWASH Hamburg GmbH
              <br />
              Osdorfer Landstraße 157 <br />
              22549 Hamburg <br />
              <br />
              Tel: 040 / 800 20-360 <br />
              Mail: info@bestcarwash-osdorf.de
              <br />
              <br />
              Registergericht: Amtsgericht Hamburg <br />
              Registernummer: HRB 63378 <br />
              Geschäftsführer: Rene Dürre <br />
              USt-IdNr. gem. § 27a UStG: DE 205165493
            </h4>
          </div>
          <div className="noSpacing">
            <h4>
              <br />
              <strong>BEST CARWASH HH-CITY</strong>
            </h4>
            <h4>
              Kessemeier Beteiligungsgesellschaft mbH <br />
              Harvestehuder Weg 37 <br />
              20149 Hamburg
              <br />
              <br />
              Tel: 040-23 07 20 <br />
              Mail: city@bestcarwash-hamburg.de
              <br />
              <br />
              Registergericht: Amtsgericht Hamburg <br />
              Registernummer: HRB 93471
              <br />
              Geschäftsführer: Tom Kessemeier <br />
              USt-IdNr. gem. § 27a UStG: DE320409876
            </h4>
          </div>

          <h4>
            <strong>—————————————–</strong>
            <br />
            <br />
            Verbraucherschlichtung: Plattform der EU-Kommission zur
            Online-Streitbeilegung: http://ec.europa.eu/consumers/odr/. <br />
            Wir sind weder bereit noch verpflichtet, an
            Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen
            teilzunehmen. <br />
            <br />
            Bildnachweis: Einzelnachweise erhalten Sie auf Anfrage über die
            Agentur für Werbung <br />
            und Unternehmenskommunikation www.supermarket.hamburg
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
