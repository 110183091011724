import "./TextPage.css";

const Saugen = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage">
          <h2>Kostenlos saugen</h2>

          <h4>
            Bei BEST CARWASH in Hamburg saugen Sie Ihr Auto vor dem Waschen
            kostenlos mit saugstarken Geräten.
          </h4>

          <h4>Und das so lange Sie wollen.</h4>

          <h4>
            Wir halten eine Vielzahl von Saugplätzen für Sie bereit, so dass es
            auch zu Stoßzeiten selten zu Wartezeiten kommt.
          </h4>

          <div className="centerImgSmaller left">
            <img className="schlauchKl" src="/assets/img/Saugen.png"></img>
          </div>

          <h4>
            Bitte seien Sie im Interesse aller Kunden so ehrlich und nutzen Sie
            die kostenlosen Saugerplätze nur in Verbindung mit einer unserer
            Wäschen.
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Saugen;
