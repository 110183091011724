import "../sub.css";
import "./BestFriends.css";

const BestFriends = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea">
          <img
            className="subContentFriendsImg"
            src="/assets/img/MOTIV_HEADER_BEST_FRIENDS.png"
          ></img>
          <div className="friendsTwoCol">
            <div>
              <h3>FREUNDSCHAFT!</h3>
              {/* <img
                className="desktopBigger"
                src="/assets/img/aktion3.png"
              ></img> */}
            </div>
            <div>
              <h3>FÜR LANGZEIT-FREUNDE</h3>
              <img src="/assets/img/FREISTELLER_KUNDENKARTEN.png"></img>
              <h4>
                Weitere Infos{" "}
                <a href="/stempel" style={{ color: "blue" }}>
                  HIER
                </a>
              </h4>
            </div>
          </div>
          {/* <h4 className="friendsSubText">
            <span className="bigFriendsHeader">ALLE JAHRE WIEDER...</span>
            <br />
            Jetzt in der Vorweihnachtszeit gibt es bei BEST CARWASH in Hamburg
            wieder den beliebten Mini-Weihnachtsstollen <br />
            für alle Leckermäuler. Achten Sie auf unsere besonderen
            Angebotswäschen… <br />
            <span style={{ color: "black", fontSize: "0.6vw" }}>
              Solange der Vorrat reicht.
            </span>
          </h4> */}

          <img className="desktopBigger" src="/assets/img/aktion3.png"></img>
          <h4 className="friendsSubText">
            <span className="actionEnd">Aktion beendet</span>
            <br />
            ALLE JAHRE WIEDER...
            <br />
            Jetzt in der Vorweihnachtszeit gibt es bei BEST CARWASH in Hamburg
            wieder den beliebten Mini-Weihnachtsstollen <br />
            für alle Leckermäuler. Achten Sie auf unsere besonderen
            Angebotswäschen… <br />
            <span style={{ color: "black", fontSize: "0.6vw" }}>
              Solange der Vorrat reicht.
            </span>
          </h4>

          <img className="desktopBigger" src="/assets/img/bfokt.png"></img>
          <h4 className="friendsSubText">
            <span className="actionEnd">Aktion beendet</span>
            <br />
            Der Oktober und der November 2021 werden eine dufte Sache...!
            Zumindest im Cockpit Ihres Autos. <br />
            Einfach an der Kasse das Stichwort "BEST FRIENDS" nennen und Sie
            erhalten von uns kostenlos eine Duftpalme in der Note "Monterey
            Vanilla" <br />
            vom Premium-Hersteller "California Scents" für eine frische Note im
            Cockpit Ihres Autos. <br />
            Solange der Vorrat reicht.
          </h4>

          <img
            className="desktopBigger"
            src="/assets/img/mobile/Handtuch.png"
          ></img>
          <h4 className="friendsSubText">
            <span className="actionEnd">Aktion beendet</span>
            <br />
            Im August & September 2021 gibt es das beliebte BEST HANDTUCH zum
            Freundschaftspreis. <br></br> Einfach beim Kauf das Stichwort "BEST
            FRIENDS" an der Kasse nennen.
          </h4>

          <h4 className="friendsSubText two">
            Das persönliche BEST HANDTUCH: einmalig kaufen – und dann bei uns
            immer wieder in ein sauberes Handtuch tauschen...
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BestFriends;
