import "./TextPage.css";

const Datenschutz = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage oz">
          <h2>Öffnungszeiten</h2>
          <br className="hideOnMobile" />
          <img src="/assets/img/EdU_Banner.png" />
          <h4>
            <strong>
              BEST CARWASH NORD <br className="onlyMobile"></br>
              <span className="textDivider comp hideDividerMobile"> | </span>
              POPPENBÜTTELER WEG 94
            </strong>
            <br></br>
            MO-FR 8:00-19:00 <br />
            SA 8:00-18:00 <br /> SO 13:00-18:00
          </h4>
          <br className="hideOnMobile" />
          <img src="/assets/img/EaW_Banner.png" />
          <h4>
            <strong>
              BEST CARWASH WEST
              <br className="onlyMobile"></br>
              <span className="textDivider comp hideDividerMobile">|</span>
              OSDORFER LANDSTRASSE 157
            </strong>
            <br></br>
            SOMMER (APR-SEPT)
            <br />
            MO-FR 8:00-19:00
            <br />
            SA 8:00-19:00
            <br />
            SO 13:00-19:00
            <br />
            <br />
            WINTER (OKT-MÄRZ)
            <br />
            MO-FR 8:00-19:00
            <br />
            SA 8:00-18:00
            <br />
            SO 13:00-18:00
          </h4>
          <br className="hideOnMobile" />
          <img src="/assets/img/HLS_Banner.png" />
          <h4>
            <strong>
              BEST CARWASH CITY
              <br className="onlyMobile"></br>
              <span className="textDivider comp hideDividerMobile">
                {" "}
                |{" "}
              </span>{" "}
              SPALDINGSTRASSE 105
            </strong>
            <br></br>
            MO-FR 8:00-19:00 <br /> SA 8:00-17:00 <br /> SO 13:00-18:00
          </h4>
          <h4>
            <strong> An gesetzlichen Feiertagen geschlossen</strong>
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Datenschutz;
