import "./TextPage.css";

const Budni = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage">
          <div className="budniMobileBreak">
            <h2>Angebote für</h2>
            <h2>BUDNI-Karten-Besitzer</h2>
          </div>

          <h4>
            BEST CARWASH Hamburg ist seit vielen Jahren beliebter
            Kooperationspartner der kostenlosen BUDNI-Karte von Hamburgs
            beliebter Drogeriemarkt-Kette.
          </h4>
          <h4>
            Mit Ihrer BUDNI-Karte erhalten Sie bei BEST CARWASH in Hamburg ein
            ausgesuchtes Premium-Wasch-Paket mit zusätzlich Extra-Schutz &
            Extra-Glanz zum Vorzugspreis.
          </h4>

          <div className="centerImgSmaller">
            <img className="budniMobile" src="/assets/img/Budni.png"></img>
          </div>

          <h4>
            Ihre kostenlose BUDNI-Karte können Sie z.B. bequem online auf "
            <a
              style={{ textDecoration: "none", color: "blue" }}
              href="https://www.budni.de/budni-karte/partner"
            >
              budni.de
            </a>
            " beantragen.
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Budni;
