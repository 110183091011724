import "../TextPage/TextPage.css";

const CityFotoArchiv = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage">
          <h2>Fotogalerie</h2>
          <div className="fotoGrid">
            <img src="/assets/img/BEST_BUNT.jpg"></img>
            <img src="/assets/img/BEST_GESAMT.jpg"></img>
            <img src="/assets/img/BEST_HOF.jpg"></img>
            <img src="/assets/img/BEST_PARTYENDE.jpg"></img>
            <img src="/assets/img/automat.png"></img>
          </div>

          <a className="backBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CityFotoArchiv;
