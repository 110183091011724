import "../../../subPages/sub.css";
import "./BestFriends.css";

const BestFriends = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea BestFriendsMobile">
          <img src="./assets/img/mobile/BestFriendsMobile.png"></img>
          <div className="friendsTwoCol">
            <div>
              <h3>FREUNDSCHAFT!</h3>
              <h4>
                "BEST FRIENDS - Goodies für Buddies" <br></br>Echte Freundschaft
                wird bei uns belohnt
              </h4>
              {/* <img src="/assets/img/aktion3.png"></img> */}
            </div>
            {/*  <h4 className="friendsSubText">
              <span className="bigFriendsHeader">ALLE JAHRE WIEDER...</span>
              <br />
              <br />
              Jetzt in der <br />
              Vorweihnachtszeit gibt es <br />
              bei BEST CARWASH <br />
              in Hamburg wieder <br />
              den beliebten
              <br /> Mini-Weihnachtsstollen <br />
              für alle Leckermäuler. <br /> <br />
              Achten Sie auf <br />
              unsere besonderen <br />
              Angebotswäschen… <br />
              <br />
              <span style={{ color: "black", fontSize: "8px" }}>
                Solange der Vorrat reicht.
              </span>
            </h4> */}

            <div style={{ marginTop: "20px" }}>
              <h3>FÜR LANGZEIT-FREUNDE</h3>
              <img src="/assets/img/FREISTELLER_KUNDENKARTEN.png"></img>
              <h4>
                Weitere Infos{" "}
                <a href="/stempel" style={{ color: "blue" }}>
                  HIER
                </a>
              </h4>
            </div>
            <img
              className="oldImg"
              src="/assets/img/aktion3.png"
              style={{ width: "60%" }}
            ></img>
            <h4 className="friendsSubText">
              <span className="actionEnd">Aktion beendet</span>
              <br />
              ALLE JAHRE WIEDER... <br />
              2021 werden eine dufte Sache...! Zumindest im Cockpit Ihres Autos.
              <br />
              Jetzt in der <br />
              Vorweihnachtszeit gibt es <br />
              bei BEST CARWASH <br />
              in Hamburg wieder <br />
              den beliebten
              <br /> Mini-Weihnachtsstollen <br />
              für alle Leckermäuler. <br /> <br />
              Achten Sie auf <br />
              unsere besonderen <br />
              Angebotswäschen… <br />
            </h4>

            <img
              className="oldImg"
              src="/assets/img/bfokt.png"
              style={{ width: "60%" }}
            ></img>
            <h4 className="friendsSubText">
              <span className="actionEnd">Aktion beendet</span>
              <br />
              Der Oktober und der November <br />
              2021 werden eine dufte Sache...! Zumindest im Cockpit Ihres Autos.
              <br />
              Einfach an der Kasse das Stichwort "BEST FRIENDS" nennen und Sie
              erhalten von uns kostenlos eine Duftpalme in der Note "Monterey
              Vanilla" vom Premium-Hersteller "California Scents" für eine
              frische Note im Cockpit Ihres Autos. <br />
            </h4>
            <img className="oldImg" src="/assets/img/mobile/Handtuch.png"></img>
            <h4 className="friendsSubText">
              <span className="actionEnd">Aktion beendet</span>
              <br />
              Im August & September 2021 gibt es das beliebte BEST HANDTUCH zum
              Freundschaftspreis. <br></br> Einfach beim Kauf das Stichwort
              "BEST FRIENDS" an der Kasse nennen.
            </h4>
          </div>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
      <img className="pfeilDown" src="/assets/img/mobile/pfeil.png"></img>
    </div>
  );
};

export default BestFriends;
