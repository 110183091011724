import "./TextPage.css";

const Stempel = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage">
          <h2>Bei jeder Wäsche sparen</h2>

          <h4>
            Als Firmenkunde oder Vielwäscher lohnen sich unsere speziellen
            Angebote. Unser Kassenpersonal informiert Sie gerne. Sicher finden
            wir auch für Sie das richtige Angebot!
          </h4>
          <h4>
            <span style={{ color: "#14a78b" }}>1 | 10er-Blöcke </span> <br></br>
            Sehr beliebt sind unsere 10er-Blöcke mit vergünstigten Wäschen.
          </h4>

          <img
            src="assets/img/10er_KARTEN.png"
            className="ZehnerKartenGrafik"
          ></img>
          <h4>
            <span style={{ color: "#14a78b" }}>2 | Stempelkarte</span>
            <br></br>
            Ganze 8,50 Euro BEST-Guthaben erhalten Sie für ein volles
            Stempelheft mit 25 Stempeln. Echte Treue lohnt sich also. <br></br>
            Vor allem, weil Sie pro Wäsche bis zu 3 Stempelpunkte sammeln
            können. Das handliche Stempel-Heft erhalten Sie beim Kassenpersonal.
            <br></br>
            Einlösbar nur am Standort Hamburg – auf Wäschen und andere
            Dienstleistungen. Guthaben oder Restguthaben nicht auszahlbar.
            <br></br>Aktion gilt bis auf Widerruf.
          </h4>
          <h4>
            <span style={{ color: "#14a78b" }}>3 | BEST-Card</span>
            <br></br>
            Mit der kostenlosen BEST-Card sparen Sie künftig bei jedem Besuch.
            Einfach Guthaben aufladen (verschiedene Guthaben-Pakete möglich){" "}
            <br className="hideOnMobile"></br>und künftig bei ausgewählten
            Wäschen und anderen Serviceleistungen
            <br className="onlyMobile"></br>von exklusiven Preisen profitieren.
          </h4>

          <img src="/assets/img/Stempelkarten.png"></img>

          <h4>
            Am Standort Poppenbüttel haben wir keine BEST Card. An Standorten
            außerhalb Hamburgs können BEST Card-Leistungen abweichen. <br></br>
            Guthaben oder Restguthaben nicht auszahlbar.
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Stempel;
