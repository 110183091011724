import "./Home.css";
import Thumbnail from "../subPageThumb/Thumbnail";
import ServiceTile from "../ServiceTile/ServiceTile";

const Home = (props) => {
  return (
    <div className="pillarCon">
      <div
        className="pillar p1"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>{" "}
      <div
        className="pillar p2"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>{" "}
      <div
        className="pillar p3"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>
      <div className="homeCon">
        <div className="p_ThumbnailCon">
          <Thumbnail
            src="/assets/img/BEST_WERBUNG_LINKSS___HH_NORD.svg"
            title="BEST CARWASH NORD"
            l1="Poppenbütteler Weg"
            l2="ERFAHR DEN UNTERSCHIED"
            url="/nord"
          />
          <Thumbnail
            src="/assets/img/BEST_WERBUNG_MITTE___HH_WEST.svg"
            title="BEST CARWASH WEST"
            l1="Osdorfer Landstraße"
            l2="EXPERTS AT WORK"
            url="/west"
          />
          <Thumbnail
            src="/assets/img/Hamburg_1.png"
            title="BEST CARWASH CITY"
            l1="Spaldingstraße"
            l2="HAMBURGS LÄNGSTE SCHAUMPARTY"
            url="/city"
          />
        </div>
        <div className="p_serviceCon">
          <h2>SERVICE BEI BEST CARWASH</h2>
          <div>
            <ServiceTile
              l1="Film"
              l2="ab!"
              src="/assets/img/movieDesktop.png"
              url="/haspa"
              onClick={props.setPopUp}
            />
            <ServiceTile
              l1="HaspaJoker"
              l2="Wäschen"
              src="/assets/img/FRAME_APP_ICON_1.svg"
              url="/haspa"
            />
            <ServiceTile
              l1="BudniCard"
              l2="Wäsche"
              src="/assets/img/FRAME_APP_ICON_2.svg"
              url="/budni"
            />
            <ServiceTile
              l1="BEST"
              l2="Wäschen"
              src="/assets/img/FRAME_APP_ICON_3.svg"
              url="/waesche"
            />
            <ServiceTile
              l1="BEST"
              l2="Pflege"
              src="/assets/img/FRAME_APP_ICON_4.svg"
              url="/pflege"
            />
            <ServiceTile
              l1="kostenlos"
              l2="Saugen"
              src="/assets/img/FRAME_APP_ICON_5.svg"
              url="/saugen"
            />
            <ServiceTile
              l1="kostenloser"
              l2="Kaffee"
              src="/assets/img/FRAME_APP_ICON_6.svg"
              url="/kaffee"
            />
            <ServiceTile
              l1="Follow us on"
              l2="Facebook"
              src="/assets/img/FRAME_APP_ICON_7.svg"
              url="/facebook"
            />
            <ServiceTile
              l1="Follow us on"
              l2="Instagram"
              src="/assets/img/IG.png"
              url="/facebook"
            />
            <ServiceTile
              l1="Goodies"
              l2="für Buddies"
              src="/assets/img/FRAME_APP_ICON_8.svg"
              url="/friends"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
