import "./TextPage.css";
import { useMediaQuery } from "react-responsive";

const Waesche = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage">
          <h2 className="washHeading">Unsere BEST Wäschen</h2>
          <img
            className="subHeadImg hideOnDesktop"
            src="/assets/img/WaescheHamburg.png"
          ></img>

          <div className="column_Two hideOnMobile">
            <div>
              <div className="budniMobileBreak noLeft washPoint">
                <h4>
                  <span style={{ color: "#14a78b" }}>
                    1 | BEST Superschaum-Wäsche{" "}
                  </span>
                  mit Spezial-Textil
                  <span className="price"> 12,50</span>
                </h4>
              </div>
              <div className="budniMobileBreak noLeft washPoint">
                <h4>
                  <span style={{ color: "#14a78b" }}>
                    2 | BEST Lackpflege-Wäsche{" "}
                  </span>
                  mit Spezial-Textil Glanzpolitur + Heißkonservierung
                  <span className="price"> 14,00</span>
                </h4>
              </div>
              <div className="budniMobileBreak noLeft washPoint">
                <h4>
                  <span style={{ color: "#14a78b" }}>
                    3 | BEST Komplettpflege-Wäsche{" "}
                  </span>
                  mit Spezial-Textil Glanzpolitur + Heißkonservierung{" "}
                  <br className="hideOnThirteen"></br>
                  <span className="washPointThree" style={{ color: "black" }}>
                    Unterbodenwäsche + Unterbodenkonservierung
                  </span>
                  <span className="price"> 18,00</span>
                </h4>
              </div>
              <div className="budniMobileBreak noLeft washPoint">
                <h4>
                  <span style={{ color: "#14a78b" }}>
                    4 | BEST Cabrio-Wäsche{" "}
                  </span>
                  mit Spezial-Textil Glanzpolitur + HighTech-Glanz
                  <span className="price"> 14,50</span>
                </h4>
              </div>
            </div>
            <img
              className="subHeadImg hideOnMobile"
              src="/assets/img/WaescheHamburg.png"
            ></img>
          </div>

          <div className="budniMobileBreak noLeft washPoint hideOnDesktop">
            <h4 style={{ color: "#14a78b" }}>1 | BEST Superschaum-Wäsche</h4>
            <h4>mit Spezial-Textil</h4>
            <h4 className="price">12,50</h4>
          </div>
          <div className="budniMobileBreak noLeft washPoint hideOnDesktop">
            <h4 style={{ color: "#14a78b" }}>2 | BEST Lackpflege-Wäsche</h4>
            <h4>mit Spezial-Textil</h4>
            <h4>Glanzpolitur + Heißkonservierung</h4>
            <h4 className="price">14,00</h4>
          </div>

          <div className="budniMobileBreak noLeft washPoint hideOnDesktop">
            <h4 style={{ color: "#14a78b" }}>3 | BEST Komplettpflege-Wäsche</h4>
            <h4>mit Spezial-Textil</h4>
            <h4>Glanzpolitur + Heißkonservierung</h4>
            <h4>Unterbodenwäsche + Unterbodenkonservierung</h4>
            <h4 className="price">18,00</h4>
          </div>

          <div className="budniMobileBreak noLeft washPoint hideOnDesktop">
            <h4 style={{ color: "#14a78b" }}>4 | BEST Cabrio-Wäsche</h4>
            <h4>mit Spezial-Textil</h4>
            <h4>Glanzpolitur + HighTech-Glanz</h4>
            <h4 className="price">14,50</h4>
          </div>

          <img src="/assets/img/Waesche.jpg"></img>


          <h4>
            <a href="/PREISLISTE_BEST_CARWASH-HH-CITY.pdf" style={{display: "flex", alignItems: "center", marginBottom: "3px"}}><img src={"/PDF_icon.svg"} style={{width: "16px", marginRight: "5px"}}/><span style={{color: "blue", marginTop: "5px"}}>Preisliste | HH-City | Spaldingstraße</span></a>
            <a href="/PREISLISTE_BEST_CARWASH-HH-OSDORF.pdf" style={{display: "flex", alignItems: "center", marginBottom: "3px"}}><img src={"/PDF_icon.svg"} style={{width: "16px", marginRight: "5px"}}/><span style={{color: "blue", marginTop: "5px"}}>Preisliste | HH-West | Osdorfer Landstraße</span></a>
            <a href="/PREISLISTE_BEST_CARWASH-HH-POPPENBUETTEL.pdf" style={{display: "flex", alignItems: "center", marginBottom: "3px"}}><img src={"/PDF_icon.svg"} style={{width: "16px", marginRight: "5px"}}/><span style={{color: "blue", marginTop: "5px"}}>Preisliste | HH-Nord | Poppenbütteler Weg</span></a>
          </h4>

          <h4 className="subLongDiv washPoint">
            Am besten immer dazu: <br></br>
            <span style={{ color: "#14a78b" }}>BEST Exklusiv-Schutz </span>
            <span style={{ color: "black" }} className="hideOnMobile">
              |{" "}
            </span>{" "}
            | HighTech-Schutz{" "}
            <span className="price">
              <br className="hideOnDesktop"></br>7,00
            </span>
            <br></br>
            <br className="hideOnDesktop"></br>
            <span style={{ color: "#14a78b" }}>BEST SuperGlanz</span> |
            HighTech-Glanz{" "}
            <span className="price">
              <br className="hideOnDesktop"></br>8,00
            </span>
          </h4>

          <h2 className="secondHeadLine">
            Bis zu 40% sparen mit unserem beliebten Kombi-Angeboten
          </h2>
          <div className="budniMobileBreak noLeft">
            <h4>
              Achten Sie vor Ort auf unsere <br className="hideOnDesktop"></br>
              beliebten, wechselnden "Kombi-<br className="hideOnDesktop"></br>
              Angebote". So bekommen Sie <br className="hideOnDesktop"></br>
              perfekte Pflege & perfekten Schutz
              <br className="hideOnMobile"></br>
              <br className="hideOnDesktop"></br> zu Ihrer Autowäsche dazu – und
              <br className="hideOnDesktop"></br>
              sparen ganz nebenbei bis zu 40%{" "}
              <br className="hideOnDesktop"></br>auf den normalen Preis.
            </h4>
          </div>

          <img
            className="hideOnMobile waschImgTwo"
            src="/assets/img/FOTO_ZUM_TEXT___WAESCHEN--2.png"
          ></img>

          <img
            className="hideOnDesktop"
            src="/assets/img/mobile/mobileWaescheZwei.png"
          ></img>

          <h4 className="subReallyLongDiv">
            An anderen BEST CARWASH-Standorten in Deutschland heißen die Wäschen
            zum Teil anders, <br className="notOnMobile"></br>entsprechen aber
            den selben hohen Qualitätsstandards.
          </h4>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
      {isTabletOrMobile && (
        <img className="pfeilDown" src="/assets/img/mobile/pfeil.png"></img>
      )}
    </div>
  );
};

export default Waesche;
