import "../sub.css";
import "./West.css";

const West = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea westDesktop">
          <img src="/assets/img/WERBE-MOTIV_LAENGLICH_HH-WEST.png"></img>
          <div>
            <div>
              <h2>
                BEST CARWASH WEST <span className="textDivider comp"> | </span>{" "}
                OSDORFER LANDSTRASSE{" "}
                <span className="textDivider comp"> | </span> EXPERTS AT WORK
              </h2>

              {/*  <div>
                <img
                  src="/assets/uk/DESKTOP_UKRAINE_UNTERSEITE_WEST.png"
                  class="specialWrapper"
                ></img>
                <h4 className="subTextBlock">
                  <h2 style={{ marginTop: "0px" }}>
                    BEST FRIENDS - <br />
                    März-Aktion
                  </h2>
                  Wir spenden 1,- Euro pro Autowäsche zur Unterstützung von
                  Hilfsaktionen für Ukraine-Flüchtlinge
                </h4>
              </div> */}

              <div className="videoWrapper">
                {/* <Player
                    playsInline
                    poster="/assets/poster.png"
                    src="/assets/Videos/trailer_city.mp4"
                  /> */}
                <video
                  controls
                  autoplay="autoplay"
                  muted="muted"
                  loop="loop"
                  playsinline="playsinline"
                  preload="metadata"
                >
                  <source
                    src="/assets/Videos/HH-OSDORF_PFLEGEHALLE.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div>
                <h4 className="subTextBlock">
                  Bei BEST CARWASH HH-WEST in der Osdorfer Landstraße waschen
                  wir seit 40 Jahren Autos – rund 30 Jahre davon unter dem
                  Marken-Dach von BEST CARWASH. <br />
                  Bei uns wird echte Handarbeit seit Jahr und Tag groß
                  geschrieben. Unser Motto ist nicht ohne Grund "Service mit
                  mehr als 2 Händen". Nicht nur beim Auto waschen sind bei uns
                  viele Hände im Einsatz, <br />
                  auch in der Pflegehalle werden Handarbeit & jahrzehntelange
                  Fachkompetenz groß geschrieben. <br />
                  Es gibt wohl kaum einen Fleck, den wir nicht entfernt
                  bekommen. So sind wir hier im Hamburger Westen zu einer echten
                  Institution rund ums Thema Waschen & Pflegen geworden…
                </h4>
              </div>

              <div className="noBottom">
                <h4>Osdorfer Landstraße 157</h4>
                <h4>22549 Hamburg</h4>
              </div>
              <div>
                <h4>
                  <a style={{ color: "blue" }} href="tel:04080020360">
                    Tel | 040-800 20-360
                  </a>
                </h4>
                <h4>
                  <a
                    style={{ color: "blue" }}
                    href="mailto:info@bestcarwash-osdorf.de"
                  >
                    Mail | info@bestcarwash-osdorf.de
                  </a>
                </h4>
              </div>
              <div>
                <h4>
                  Öffnungszeiten <br />
                  SOMMER (APR-SEPT) <br />
                  MO-FR 8:00-19:00 <br />
                  SA 8:00-19:00 <br />
                  SO 13:00-19:00 <br />
                  <br />
                  WINTER (OKT-MÄRZ) <br />
                  MO-FR 8:00-19:00 <br />
                  SA 8:00-18:00 <br />
                  SO 13:00-18:00 <br />
                  <br />
                  An gesetzlichen Feiertagen geschlossen
                </h4>
              </div>
            </div>
          </div>

          <h4 className="impMsg">
            DIESE SEITE BEFINDET SICH ZUR ZEIT IM AUFBAU. AKTUELLE INFOS ZU UNS
            & UNSEREN AKTIONEN DEMNÄCHST HIER.
          </h4>
          <a className="backBtn unset" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default West;
