import "./TextPage.css";

const Abmessungen = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage">
          <h2>Passt!</h2>

          <h4>
            Bei BEST CARWASH Hamburg haben wir viel Erfahrung und die passende
            Technik, <br className="hideOnMobile"></br>um Fahrzeuge mit den
            unterschiedlichsten Abmessungen sicher und sauber zu waschen.
          </h4>
          <img className="smallerImg" src="/assets/img/Abmessungen.png"></img>
          <h4>
            Vom tiefen Lamborghini, Jeep Wrangler, Dodge RAM über Ford Nugget
            bis hin zum VW-Multivan (auch mit Markise){" "}
            <br className="hideOnMobile"></br>waschen{" "}
            <br className="hideOnDesktop"></br>wir dicke, dünne, breite & hohe
            Fahrzeuge...
          </h4>

          <h4>Sondermaße bitte vor Ort klären.</h4>

          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Abmessungen;
