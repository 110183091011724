import "./mobileHome.css";
import Thumbnail from "../../Global/subPageThumb/Thumbnail";

const MobileHome = () => {
  return (
    <div className="mobileHomeCon">
      <div>
        <Thumbnail
          src="/assets/img/BEST_WERBUNG_LINKSS___HH_NORD.svg"
          title="BEST CARWASH NORD"
          l1="Poppenbütteler Weg"
          l2="ERFAHR DEN UNTERSCHIED"
          url="/nord"
        />
      </div>
      <div>
        <Thumbnail
          src="/assets/img/BEST_WERBUNG_MITTE___HH_WEST.svg"
          title="BEST CARWASH WEST"
          l1="Osdorfer Landstraße"
          l2="EXPERTS AT WORK"
          url="/west"
        />
      </div>
      <div>
        <Thumbnail
          src="/assets/img/Hamburg_1.png"
          title="BEST CARWASH CITY"
          l1="Spaldingstraße"
          l2="HAMBURGS LÄNGSTE SCHAUMPARTY"
          url="/city"
        />
      </div>
    </div>
  );
};

export default MobileHome;
