import "./App.css";
import "./Components/Mobile/tabletOpt.css";
import "./fonts/fonts.css";

//Pages
import Landing from "./Components/Global/Landing/Landing";
import MobileLanding from "./Components/Mobile/MobileLanding";

//Dependancies
import { useMediaQuery } from "react-responsive";
import MetaTags from "react-meta-tags";
import { useEffect, useState } from "react";

function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [popUp, setPopUp] = useState(false);

  return (
    <div>
      <MetaTags>
        <title>BEST CARWASH Hamburg</title>
        <meta
          name="description"
          content="Hamburgs führende Autowasch-Marke. 3 Mal anders, 3 Mal BEST."
        />
      </MetaTags>



      {isTabletOrMobile && <MobileLanding setPopUp={setPopUp} />}
      {!isTabletOrMobile && <Landing setPopUp={setPopUp} />}

      {popUp && (
        <div className="videoPopUpWrapper">
          <img
            className="closePopUp"
            src="/assets/img/close.png"
            onClick={() => {
              setPopUp(false);
            }}
          />
          <video
            controls
            autoplay="autoplay"
            muted="muted"
            loop="loop"
            playsinline="playsinline"
            preload="metadata"
          >
            <source
              src="/assets/Videos/BEST_CARWASH_ERLEBNISWELT_HAMBURG.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      )}
    </div>
  );
}

export default App;
