import "./TextPage.css";

const About = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage">
          <h2>Die BEST CARWASH-Gruppe</h2>

          <h4>
            Die BEST CARWASH-Gruppe verfügt aktuell über rund 30 Stationen in
            ganz Deutschland.
          </h4>
          <h4>
            Als "Pioniere der textilen Autowäsche" hat BEST CARWASH in
            Deutschland Standards gesetzt, die heute für eine ganze Branche
            selbstverständlich sind.
          </h4>

          <h4>
            Neuste Waschtechnik und ständige neutrale Qualitätskontrollen
            sichern seit Jahren den Unternehmensanspruch,{" "}
            <br className="hideOnMobile"></br>unseren Kunden das bestmögliche
            Waschergebnis zu liefern.
          </h4>

          <h4 style={{ marginBottom: "30px" }}>
            Wir wachsen weiter. Möchten Sie Teil unserer Gruppe werden? Besuchen
            Sie unsere Gruppen-Website
            <a
              style={{ textDecoration: "none", color: "blue" }}
              href="https://www.bestcarwash.de"
              className="aboutLineLeft"
            >
              www.bestcarwash.de
            </a>
          </h4>

          <h2 style={{ marginBottom: "5px" }}>Purple rain, purple rain...</h2>

          <img src="/assets/img/about.png"></img>
          <h6
            style={{
              color: "grey",
              margin: "0px",
              fontSize: "8px",
              lineHeight: "100%",
            }}
          >
            Impression vom Standort <br className="hideOnDesktop"></br>Hamburg
            City
          </h6>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
