import "./TextPage.css";
import { useMediaQuery } from "react-responsive";

const Facebook = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      {/* /assets/img/facebookBGMobile.png */}

      <div className="subContentWrapper">
        <div className="subContentArea textPage facebook">
          <img
            src={`${
              isTabletOrMobile
                ? "/assets/img/facebookBGMobile.png"
                : "/assets/img/facebookHeaderDesktop.png"
            }`}
          ></img>
          <h2>Facebook & Instagram</h2>
          <h4>
            Folge uns bei Facebook und verpasse nie die neusten BEST
            FRIENDS-Aktionen <br />
            und alle anderen Neuigkeiten von lustig bis wissenswert…
          </h4>

          <div className="facebookLogoItems">
            <img src="/assets/img/FB-Logo.png"></img>
            <h4>
              <a href="https://www.facebook.com/BCWHHNORD/">
                BEST CARWASH HH-Nord
              </a>
              <br />
              <a href="https://www.facebook.com/Best-Carwash-Hamburg-Osdorf-170066413032653/">
                BEST CARWASH HH-West
              </a>
              <br />
              <a href="https://www.facebook.com/bestcarwashcity/">
                BEST CARWASH HH-City
              </a>
            </h4>
          </div>

          <div className="facebookLogoItems">
            <img src="/assets/img/INSTA-Logo.png"></img>
            <h4>
              <a href="https://www.instagram.com/bestcarwashpoppenbuettel/?hl=de">
                BEST CARWASH HH-Nord
              </a>
              <br />
              <a href="https://www.instagram.com/bestcarwash_osdorf/?hl=de">
                BEST CARWASH HH-West
              </a>
              <br />
              <a href="https://www.instagram.com/bestcarwashcity/?hl=de">
                BEST CARWASH HH-City
              </a>
            </h4>
          </div>

          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Facebook;
