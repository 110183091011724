import "./TextPage.css";

const Jobs = () => {
  return (
    <div className="subContainer">
      <div
        className="sb one"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_LINKS.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb two"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_MITTE.svg" +
            ")",
        }}
      ></div>
      <div
        className="sb three"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/BETONWAND_RECHTS.svg" +
            ")",
        }}
      ></div>

      <div className="subContentWrapper">
        <div className="subContentArea textPage">
          <h2 style={{ marginBottom: "20px" }}>License to Wash</h2>
          <img src="/assets/img/Jobs.png"></img>

          <h4>
            <strong>
              Komm ins Team <br></br>
            </strong>
            Du möchtest bei BEST CARWASH in Hamburg arbeiten? <br></br>Sprich
            einfach unser Personal direkt vor Ort auf der Anlage an.
          </h4>
          <h4 style={{ marginBottom: "1px" }}>
            <strong>
              Du magst es lieber schriftlich? <br></br>
            </strong>
          </h4>
          <div className="budniMobileBreak noSpacing">
            <h4>
              <a
                style={{ marginRight: "4px" }}
                href="https://bestcarwash.de/jobs-bei-best-carwash/"
              >
                HIER
              </a>
              geht es zum offiziellen Bewerbungsportal der
            </h4>
            <h4>BEST CARWASH-Gruppe.</h4>
          </div>
          <a className="backHomeBtn" href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
