import "./Thumbnail.css";

const Thumbnail = (props) => {
  return (
    <a style={{ textDecoration: "none" }} href={props.url}>
      <div className="thumbnailCon">
        <img src={props.src}></img>
        <h2>
          {props.title} <div className="infoDiv">INFOS HIER</div>
        </h2>
        <div className>
          <h3>{props.l1}</h3>
          <h4>{props.l2}</h4>
        </div>
      </div>
    </a>
  );
};

export default Thumbnail;
